.without_borders{
    padding: 0px;
}
.footer_container{
    padding: 0px 40px;
}

.footer_logo{
    width: 6.5rem;
    height: 6.5rem;
}