.App {
  text-align: left;
  background-color: #ffffff;
}

.App-logo-container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
  align-self: center;
  align-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    align-self: center;
    align-content: center;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
  --header-height: 3.5rem;


  --first-color: hsl(195, 100%, 30%);
  --gradient-color: linear-gradient(90deg,
      hsl(210, 55%, 20%),
      hsl(192, 62%, 25%));
  --title-color: hsl(210, 52%, 20%);
  --text-color: hsl(210, 24%, 15%);
  --white-color: #fff;
  --body-color: #f8f8f8;


  --body-font: "Poppins", sans-serif;
  --second-font: "Lora", serif;
  --display-medium-size: 56px;
  --title-large-size: 20px;
  --normal-font-size: 18px;
  --small-font-size: .813rem;

  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Small phones (640px and down) */
@media only screen and (max-width: 40em) {
  :root {
    --display-medium-size: 20px;
    --title-large-size: 16px;
    --normal-font-size: 14px;
    --small-font-size: .875rem;
  }
}

/* Small devices such as large phones (640px and up) */
@media only screen and (min-width: 40em) {
  :root {
    --display-medium-size: 24px;
    --title-large-size: 16px;
    --normal-font-size: 16px;
    --small-font-size: .875rem;
  }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48em) {
  :root {
    --display-medium-size: 24px;
    --title-large-size: 16px;
    --normal-font-size: 14px;
    --small-font-size: .875rem;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
  :root {
    --display-medium-size: 32px;
    --title-large-size: 17px;
    --normal-font-size: 15px;
    --small-font-size: .875rem;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
  :root {
    --display-medium-size: 56px;
    --title-large-size: 20px;
    --normal-font-size: 18px;
    --small-font-size: .875rem;
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

header {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-regular);
}
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-regular);
  background-color: white;
  color: var(--text-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}


.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.main {
  overflow: hidden;
}
