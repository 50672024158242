/* Visuals sizes */

.screen_title{
  font-size: var(--display-medium-size);
  margin-top: 10px;
  margin-bottom: 40px;
  padding-left: 8px;
}

.article_container{
    background: #F8F9FA;
    display: flex;
    padding: 16px 4px;
    align-items: stretch;
}

.text_container{
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
}

.article_image {
    /*TODO: needs to fill width*/
    height: 100%;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
}

.image_container{
  position: relative;
}
.magazine_text{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */
  display: flex;
  align-items: center;
  color: #222222;
  padding: 0px 16px;
  margin-bottom: 16px;
  background: #E8F9FF;
  height: 32px;
  width: fit-content;
  border-radius: 16px;
}

.article_image_text_overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 10px 26px; /*26 = 10 + 16 (ignored by absolute position)*/
  background: white;
  color: #222222;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  width: fit-content;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
}

.section_button {
  display: flex;
  padding: 5px 0px;
  justify-content: left;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.read_article_button {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
  background: linear-gradient(94deg, #4BA2D1 12.28%, #1D7AAC 101.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.authors_word{
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
    .article_container {
        border-radius: 16px;
    }
    .screen_title{
      margin-top: 4px;
      margin-bottom: 16px;
    }

}

@media only screen and (min-width: 768px) {
    .article_container {
        border-radius: 24px;
    }
    .screen_title{
      margin-top: 4px;
      margin-bottom: 24px;
    }
}

@media only screen and (min-width: 992px) {
    .article_container {
        border-radius: 32px;
        max-width: 1400px;
    }
    .screen_title{
      margin-top: 10px;
      margin-bottom: 40px;
    }
}

/* Text Sizes */
@media only screen and (max-width: 575px) {
  .article_title{
    font-size: 14px;

  }
  .article_subtitle{
    font-size: 12px;
  }

  .read_article_button{
    font-size: 13px;
  }
}


@media only screen and (min-width: 576px) {
  .article_title{
    font-size: 15px;
  }
  .article_subtitle{
    font-size: 12px;
  }

  .read_article_button{
    font-size: 13px;
  }
}


@media only screen and (min-width: 768px) {
  .article_title{
    font-size: 16px;
  }
  .article_subtitle{
    font-size: 13px;
  }

  .read_article_button{
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) {
  .article_title{
    font-size: 20px;
  }
  .article_subtitle{
    font-size: 14px;
  }

  .read_article_button{
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .article_title{
    font-size: 22px;
  }
  .article_subtitle{
    font-size: 14px;
  }

  .read_article_button{
    font-size: 16px;
  }
}

@media screen and (min-width: 1320px) {
  .article_title{
    font-size: 22px;
  }
  .article_subtitle{
    font-size: 14px;
  }

  .read_article_button{
    font-size: 16px;
  }
}

.article_title{
    font-weight: 500;
}

.article_subtitle{
    font-weight: 400;
}