.page{
    background-color: #f8f8f8;
}
.container {
    width: 80%;
    padding: 20px 10px;
    box-sizing: border-box;
    align-content: center;
    margin: auto;
  }
  .title{
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
  }
  .subtitle{
    font-weight: bold;
  }
  .text{
    text-align: justify;
  }