/* navbar styles*/
.header {
  height: 250px;
  width: 100%;
  top: 8px;
  z-index: 1;
  transition: .3s ease-in;
  overflow: hidden;
}

.nav {
  position: fixed;
  display: flex;
  height: auto;
  min-height: 56px;
  width: 100%;
  background: #F8F9FA;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;
}

@media only screen and (max-width: 767px) {
  .nav {
    padding: 12px 8px 12px 16px;
  }
}

ul {
  list-style-type: none;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.nav_link{
  color: #818181;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0px 8px;
}
.nav_link:hover{
	color: #38A3DE;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.active{
  color: #38A3DE;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.logo_container {
  display: inline-flex;
  align-items: center;
  column-gap: .5rem;
  width: auto;
}

.logo_image {
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
}

.title {
  color: #222;
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  text-transform: capitalize;
}
.title:hover{
  color: var(--first-color);
}


.rounded_button{
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 40px;
}

select {
  border: 0px;
  background: #F8F9FA;
  font-size: 15px;
  color: #222222;
  
}

.select_text{
  text-align: center;
  width: fit-content;
}

option{
  font-size: 15px;
}

select:focus {
  outline: none;
}
