/* Small phones (640px and down) */
@media only screen and (max-width: 767px) {
  .row_1_container {
    border-radius: 32px;
    background: #F8F9FA;
    display: flex;
    padding: 16px 4px;
    gap: 16px;
    align-items: stretch;
  }
  .row1_column1_container {
    border: 2px solid #E3E3E3;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    padding: 32px 40px;
    flex-direction: column;
    align-self: stretch;
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: flex-start;
  }

  .section_title {
    color: #222;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
    /* 78.4px */
  }

  .section_subtitle {
    color: #222;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    /* 25.2px */
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .row_1_container {
    border-radius: 32px;
    background: #F8F9FA;
    display: flex;
    padding: 16px 4px;
    align-items: stretch;
  }
  .row1_column1_container {
    border: 2px solid #E3E3E3;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    padding: 32px 40px;
    flex-direction: column;
    align-self: stretch;
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: flex-start;
  }

  .section_title {
    color: #222;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 78.4px */
    margin-bottom: 20px;
  }

  .section_subtitle {
    color: #222;
  
    font-family: Poppins;
    font-size: var(--normal-font-size);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 25.2px */
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .row_1_container {
    border-radius: 48px;
    background: #F8F9FA;
    display: flex;
    padding: 32px 24px;
    align-items: stretch;
    max-width: 1400px;
  }
  .row1_column1_container {
    border: 2px solid #E3E3E3;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    padding: 32px 40px;
    flex-direction: column;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .section_title {
    color: #222;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 78.4px */
    margin-bottom: 16px;
  }

  .section_subtitle {
    color: #222;
  
    font-family: Poppins;
    font-size: var(--normal-font-size);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 25.2px */
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px){
  :root{
    --display-large-size: 32px;
    --aspect-ratio: 1;
    --section1-subtitle-text-size: 16px;
    --generic-row-horizontal-padding: 16px;
    --generic-row-title-text-size: 24px;
    --row-col-separator: 0px;
    --generic-text-section-align-items: center;
    --generic-text-section-bottom-padding: 16px;
    --generic-section-title-size: 20px;
    --generic-row-vertical-padding: 32px;
    --generic-justify-content: center;
  }
  
}

@media only screen and (min-width: 576px){
  :root{
    --display-large-size: 48px;
    --aspect-ratio: 1;
    --section1-subtitle-text-size: 18px;
    --generic-row-title-text-size: 24px;
    --generic-row-horizontal-padding: 24px;
    --row-col-separator: 0px;
    --generic-text-section-align-items: center;
    --generic-text-section-bottom-padding: 16px;
    --generic-row-vertical-padding: 32px;
    --generic-justify-content: center;
  }
}

@media only screen and (min-width: 768px){
  :root{
    --display-large-size: 40px;
    --aspect-ratio: 0.83;
    --section1-subtitle-text-size: 16px;
    --generic-row-title-text-size: 24px;
    --generic-row-horizontal-padding: 24px;
    --generic-row-vertical-padding: 32px;
    --row-col-separator: 16px;
    --generic-text-section-align-items: flex-start;
    --generic-text-section-bottom-padding: 0px;
    --generic-justify-content: flex-start;
  }
}

@media only screen and (min-width: 992px){
  :root{
    --display-large-size: 56px;
    --aspect-ratio: 0.83;
    --section1-subtitle-text-size: 18px;
    --generic-row-title-text-size: 32px;
    --generic-row-horizontal-padding: 40px;
    --generic-row-vertical-padding: 64px;
    --row-col-separator: 44px;
    
  }
}

@media only screen and (min-width: 1200px){
  :root{
    --display-large-size: 64px;
    --aspect-ratio: 0.83;
    --section1-subtitle-text-size: 20px;
    --generic-row-title-text-size: 56px;
    --generic-row-horizontal-padding: 80px;
    --generic-row-vertical-padding: 64px;
    --row-col-separator: 44px;
  }
}

@media only screen and (min-width: 1320px){
  :root{
    --display-large-size: 72px;
    --aspect-ratio: 0.83;
    --section1-subtitle-text-size: 20px;
    --generic-row-title-text-size: 56px;
    --generic-row-horizontal-padding: 80px;
    --generic-row-vertical-padding: 64px;
  }
}

.row1_section2 {
  aspect-ratio: var(--aspect-ratio);
}

.row1_section2_image {
  /*TODO: needs to fill width*/
  height: 100%;
  width: 100%;
  border-radius: 25px;
  object-fit: cover;
}

.home_title {
  align-self: stretch;
  color: #222;
  font-family: Poppins;
  font-size: var(--display-large-size);
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 86.4px */
}

.home_description {
  color: #9D9D9D;
  font-family: Poppins;
  font-size: var(--section1-subtitle-text-size);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
}


.button_container {
  min-width: 380px;
}

.know_more_button {
  display: inline-block;
  background-color: var(--first-color);
  color: var(--white-color);
  padding: 1rem 20px;
  border-radius: 2rem;
  box-shadow: 0 24px 32px hsla(210, 54%, 24%, .3);
}

.see_products_button {
  color: var(--first-color);
  padding: 1rem 2rem;
  width: auto;
}

.row2_container {
  display: flex;
  padding: var(--generic-row-vertical-padding) var(--generic-row-horizontal-padding);
  align-items: flex-start;
  background-color: white;
}

.section_column1{
  padding-right: var(--row-col-separator);
  align-self: center;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding-left: 0px; /*Bootstrap is adding padding automatically*/
}

.section_column2 {
  padding-left: var(--row-col-separator);
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px; /*Bootstrap is adding padding automatically*/
}

.section_text_column {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: var(--generic-text-section-align-items);
  gap: 12px;
  flex-direction: column;
  align-self: center;
  margin-bottom: var(--generic-text-section-bottom-padding);
}

.section_public {
  color: #126FA2;
  text-align: center;
  font-family: Poppins;
  font-size: var(--title-large-size);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
}

.section_divider{
  width: 120px;
  height: 6px;
  background-color: #95D2EB;
  border-radius: 3px;
}

.section_button {
  display: flex;
  padding: 5px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.section_button_text {
  font-family: Poppins;
  font-size: var(--title-large-size);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
  background: linear-gradient(94deg, #4BA2D1 12.28%, #1D7AAC 101.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section_button_arrow {
  width: 24px;
  height: 0px;
  stroke-width: 1.2px;
  stroke: #4BA2D0;
}

.rounded_image{
  border-radius: 40px;
}

.entities_section_container{
  display: flex;
  margin: 24px 0px;
  padding: 64px 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 48px;
  background: #E8F9FF;
}

.entities_section_title{
  color: #222;
  font-family: Poppins;
  font-size: var(--generic-row-title-text-size);
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 78.4px */
}

.entities_subsection_title_container{
  align-items: center;
}

.entities_international_subsection_title_container{
  align-items: center;
  margin-top: 16px;
}

.entities_section_divider{
  width: 80px;
  height: 4px;
  background-color: #95D2EB;
  border-radius: 2px;
}

.entities_section_subsection_title{
  color: #222;
  font-family: Poppins;
  font-size: var(--section1-subtitle-text-size);
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 78.4px */
  white-space: nowrap;
}

.built_by_container{
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 24px 32px;
  justify-content: center;
  align-self: stretch;
  border-radius: 48px;
  border: 1px solid #E3E3E3;
}

.entities_section_images_container{
  display: flex;
  width: auto;
  max-width: 1003px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;
  flex-wrap: wrap;
}

.entites_image_container{
  display: flex;
  height: 136px;
  width: 136px;
  border-radius: 32px;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 4px 4px;
}

.about_us_section_container{
  display: flex;
  margin: 24px 32px;
  padding: 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 48px;
  border: 1px solid #E3E3E3;
}

.about_us_section_image{
  border-radius: 24px;
}

.section7_container{
  gap: 32px;
}
.financied_by_container{
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 24px 32px;
  justify-content: center;
  align-self: stretch;
  border-radius: 48px;
  gap: 8px;
  border: 1px solid #E3E3E3;
}

.financied_by_column1_container{
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}

.financied_by_text_column {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: var(--generic-justify-content);
  align-self: center;
}

.financied_by_title{
  color: #222;
  font-family: Poppins;
  font-size: var(--section1-subtitle-text-size);
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 56px */
  white-space: nowrap;
}

.financied_by_subtitle {
  color: #222;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
}

